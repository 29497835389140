.container {
  /* font-family: "思源黑体"; */
  margin: auto;
  max-width: 500px;
  padding-bottom: 60px;
}

.header {
  display: flex;
  direction: row;
  justify-content: space-between;
  height: 145px;
  width: 100%;
  background: #0068B7;
}

.header--title {
  height: 65px;
  margin-left: 30px;
  margin-top: 40px;
  color: white;
}

.header--title--main {
  font-size: 24px;
  font-weight: 900;
}

.header--title--sub {
  font-size: 14px;  
  font-weight: 600;
}

.header--image {
  margin-top: 10px;
  margin-right: 24px;
}

.home-content {
  margin: 0 20px 0px 20px;
}

.home-card {
  margin-top: 80px;
  display: flex;
}

.home-card--circle {
  width: 110px;
  height: 110px;
  border-radius: 55px;
  padding: 30px;
  margin-right: 12px;
  background-color: #cce1f1;
}

.home-card-icon-parent{
  position:relative;
  margin:0 auto;
  width:52px;
  height:52px;
  opacity: 1;
 }
 .home-card-icon-children {
  position:absolute; 
  left:50%; 
  top:50%; 
  margin:-21px 0 0 -21px ;
  height:42px;
  width:42px;
  opacity: 1;
 }

.home-card--text--main {
  color: #0068b7;
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
}

.home-card--text--sub {
  font-size: 14px;
  font-weight: 400;
  color: #999ea1;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.result-header {
  display: flex;
  justify-content: space-between;
}

.result-header--image {
  margin-top: -62px;
  margin-left: -30px;
  opacity: 1;
}

.placeholder {
  width: 60px;
  background: #0068B7;
  border-radius: 0 0 30px 0;
}

.calc-header {
  background: white;
}

.calc-header--bg {
  display: flex;
  justify-content: space-between;
  height: 72px;
  width: 100%;
  padding-left: 20px;
  background: #0068B7;
  border-radius: 0 0 30px 0;
}

.result-header--bg {
  height: 72px;
  width: 100%;
  padding: 17px 0 0 0;
  background: #0068B7;
  display: flex;
}

.calc-header--text {
  color: white;
  margin-left: 25px;
  margin-top: 23px;
  font-size: 18px;
  font-weight: 900;
}

.calc-header--image {
  margin-top: -45px;
  margin-right: 25px;
}

.instruct-header--image {
  margin-top: 2px;
  margin-right: 25px;
}

.calc-footer {
  margin: 18px 20px 18px 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: justify;
}

.calc-footer > span {
  color: #0068b7;
}

.instruction-footer > span {
  color: #0068b7;
}
.result-header--layout{
  
  padding: 0px 15px 15px 0px;
  width: 100%;
  display:flex;
  text-align: center;
  writing-mode:horizontal-tb;
  flex-direction: column;
  justify-content: center;
  
}

.result-header--text {
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.result-header--layout{
  
  padding: 0px 15px 15px 0px;
  width: 100%;
  display:flex;
  text-align: center;
  writing-mode:horizontal-tb;
  flex-direction: column;
  justify-content: center;
  
}

.curve-container {
  height: 42px;
  width: 100%;
  background-color: #0068B7;
}

.curve {
  padding-top: 15px;
  height: 42px;
  width: 100%;
  border-radius: 30px 0 0 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.result-curve {
  padding: 16px 20px 0px 20px;
  height: 42px;
  width: 100%;
  border-radius: 30px 0 0 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.instruct-curve {
  height: 42px;
  width: 100%;
  border-radius: 30px 0 0 0;
  background-color: white;
  padding: 16px 0 0 21px;
}

.instruct-title {
  font-size: 14px;
  font-weight: 700;
}

.calc-form {
  margin: 0 20px 0 20px;
}

.form-label {
  display: flex;
  justify-content: space-between;
  background-color: #0068B7;
  color: white;
  padding: 6px 6px 6px 15px;
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  font-weight: bold;
}

.form-field {
  background-color: #f2f3f3;
  color: #2e2e2e;
  padding: 11px 0 11px 15px;
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  margin-bottom: 25px;
}

.submit-field {
  margin: 20px 80px 20px 80px;
  background-color: #cce1f1;
  border-radius: 10px;
  padding: 11.5px 0 11.5px 0;
  text-align: center;
}

.pill-list {
  margin: 2px 0 2px 0;
}

.error {
  text-align: center;
  color: red;
}

.button-text {
  color: #2e2e2e;
  font-size: 14px;
  font-weight: 700;
}

.insurance-text {
  color: #199eff;
  font-size: 10px;
  margin: 8px 16px 0 0;
}

.copay-text {
  color: #cce1f1;
  font-size: 10px;
  margin: 8px 16px 0 0;
}

.table-header {
  font-size: 12px;
  color: #707172;
  line-height: 14px;
}

.table-header--name {
  padding-left: 5px;
}

.table-row {
  padding: 8px 0 8px 0;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 700;
  background-color: #f2f3f3;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 8px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-item-2 {
  text-align: center;
  flex: 2;
}

.flex-item-3 {
  text-align: center;
  flex: 3;
}

.flex-item-4 {
  text-align: center;
  flex: 4;
}

.flex-item--name {
  font-size: 12px;
  padding-left: 5px;
  line-height: 14px;
}

.footer-text-blue {
  color: #0068B7;
  font-size: 16px;
  font-weight: 400;
  margin: 8px 20px 8px 20px;
}

.footer-text-h {
  font-size: 12px;
  margin: 8px 20px 0px 20px;
  font-weight: 400;
  color:#0068B7
}

.footer-text-p {
  font-size: 12px;
  font-weight: 400;
  margin: 0px 20px 0px 20px;
  line-height:16px;
}

.image-container {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 8px 20px 20px 20px;
}

.image-container::-webkit-scrollbar {
  display: none;
}

.pill-item {
  margin-right: 24px;
}

.pill-image-border {
  border: 3px solid #0068B7;
  border-radius: 40px;
  filter: drop-shadow(0px 3.07624px 3.07624px rgba(0, 0, 0, 0.25));
  margin-bottom: 8px;
}

.pill-image {
  border-radius: 40px;
  filter: drop-shadow(0px 3.07624px 3.07624px rgba(0, 0, 0, 0.25));
  margin-bottom: 8px;
}

.pill-name {
  color: #828282;
  font-size: 10px;
  max-width: 70px;
  text-align: center;
  line-height: 12px;
}

.pill-title {
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  color:#0068B7;
}

.more-container {
  background-color: white;
  text-align: center;
  right: 20px;
}

.more-icon {
  margin-bottom: 8px;
  margin-left: 0px;
}
.more-text {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
  text-align: center;
  line-height: 12px;
}

.card-container {
  margin: 12px 20px 16px 20px;
  display: flex;
}

.card-left {
  border-radius: 10px 0 0 10px;
  background-color: #0068B7;
  color: white;
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  text-align: center;
  flex-direction: column;
  display:flex;
  justify-content: center;
  width: 36px;
}

.card-right {
  padding: 15px 15px 0px 0px;
  border-radius: 0 10px 10px 0;
  background-color: #f2f3f3;
  width: 100%;
  display:flex;
  writing-mode:horizontal-tb;
  flex-direction: column;
  justify-content: center;
}

.instruction-footer {
  margin: 10px 20px 20px 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.instruction-footer > h3 {
  font-size: 14px;
  font-weight: 400;
  color: #0068b7;
}

ul > li {
  font-size: 12px;
  font-weight: 400;
  word-break: break-all;
}

ul > li > span {
  color: #828282;
}

footer {
  max-width: 500px;
  margin: auto;
  background-color: white;
  position: fixed;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  height: 49px;
  width: 100%;
}

.menubar {
  margin-top:10px;
  margin-bottom:10px;
  display: flex;
  justify-content: space-around;
}

.row-width{
  width:84px;
}

.row1 {
  color: #0068b7;
  font-size: 12px;
  font-weight: normal;
  /* margin: 4px 10px 4px 30px; */
  text-align: center;
  display:flex;
  justify-content: center;
  margin:auto;
}

.row2 {
  color: #2e2e2e;
  width:50px;
  font-size: 12px;
  font-weight: normal;
  margin: auto;
  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: row;
}

.popup-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  padding: 4px;
}

.popup-confirm,
.popup-cancel {
  color: #1677ff;
  padding: 8px;
}
